import { useEffect, useState } from 'react';
import Link from 'next/link';
import { getCookie, setCookie } from 'cookies-next';
import styled, { keyframes } from 'styled-components';
import { PrimaryButton, SecondaryButton } from '@/components/core/button';

import { insertGTMNoScript, insertGTMScript } from './gtm';
import GTMPageViewTracker from './GTMPageViewTracker';

const COOKIES_KEY = 'bloom_cookies_acceptance';
const COOKIES_PATH = '/';
const COOKIES_DOMAIN = 'hibloom.com';

const animation = keyframes`
  from {
    opacity: 0;
  } to {
      opacity: 1;
    }
`;

const CookieBannerWrapper = styled.div`
  background-color: ${(props) => props.theme.colors.white};
  border-radius: 4px;
  box-shadow: 0 10px 20px rgba(0, 0, 0, 0.2);
  position: fixed;
  right: 20px;
  bottom: 40px;
  min-width: 370px;
  max-width: 370px;
  padding: 35px 30px;
  z-index: 10;
  animation: ${animation} 300ms ease-in;

  display: ${(props) => (props.hidden ? 'none' : 'block')};

  h4 {
    font-size: 18px;
    color: ${(props) => props.theme.colors.brown};
    padding-bottom: 15px;
    line-height: 18px;
  }

  p {
    color: ${(props) => props.theme.colors.brown};
    line-height: 26px;
  }

  a {
    text-decoration: underline;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    width: 100vw;
    max-width: 100vw;

    bottom: 0;
    left: 0;
  }
`;

const ButtonsWrapper = styled.div`
  display: flex;
  padding-top: 15px;

  button:first-child {
    margin-right: 10px;
  }
`;

export default function CookieBanner() {
  const [selection, setSelection] = useState(false);
  const [show, setShow] = useState(false);

  useEffect(() => {
    function onScroll() {
      if (selection === undefined) {
        setShow(true);
      }
    }

    window.addEventListener('scroll', onScroll, { once: true });

    () => window.removeEventListener('scroll', onScroll, { once: true });
  }, [selection]);

  useEffect(() => {
    setSelection(getCookie(COOKIES_KEY, { path: COOKIES_PATH, domain: COOKIES_DOMAIN }));
  }, []);

  function acceptCookies() {
    setSelection(true);
    const expireDate = new Date();
    expireDate.setMonth(expireDate.getMonth() + 1);
    setCookie(COOKIES_KEY, true, {
      path: COOKIES_PATH,
      domain: COOKIES_DOMAIN,
      expires: expireDate,
    });
  }

  function rejectCookies() {
    setSelection(false);
    setCookie(COOKIES_KEY, false, { path: COOKIES_PATH, domain: COOKIES_DOMAIN });
  }

  if (selection === undefined && show) {
    return (
      <CookieBannerWrapper>
        <h4>Cookies</h4>

        <p>
          {`We use cookies to provide you with the best possible experience. For further information
          please read our `}
          <Link href="/legal/privacy-statement" target="_blank" rel="noopener noreferrer">
            Privacy Statement
          </Link>
          .
        </p>

        <ButtonsWrapper>
          <PrimaryButton onClick={acceptCookies} small>
            Accept
          </PrimaryButton>
          <SecondaryButton onClick={rejectCookies} small>
            Reject
          </SecondaryButton>
        </ButtonsWrapper>
      </CookieBannerWrapper>
    );
  }

  if (selection) {
    return (
      <>
        {/* Google Tag Manager - Global base code */}
        {insertGTMScript()}
        {/* Google Tag Manager (noscript) */}
        {insertGTMNoScript()}

        <GTMPageViewTracker />
      </>
    );
  }

  return null;
}
