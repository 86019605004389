import React, { useContext, useMemo } from 'react';
import styled, { css } from 'styled-components';
import { useRouter } from 'next/router';
import cn from 'classnames';

import { SecondaryButton } from '@/components/core/button';
import NavigationContext from '@/contexts/navigation-context';
import Link from 'next/link';

const ListItem = ({ url, extraClass, children }) => {
  const router = useRouter();
  const { setIsOpen } = useContext(NavigationContext);

  const Component = useMemo(() => (url ? Link : React.Fragment), [url]);

  return (
    <NavItem
      className={cn(extraClass)}
      $active={url === router.pathname}
      onClick={() => {
        setIsOpen(false);
      }}
    >
      <Component {...(url ? { href: url } : {})}>{children}</Component>
    </NavItem>
  );
};

const NavListWrapper = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    top: 0;
    left: 0;
    width: 100vw;
    height: 100vh;
    background-color: #fcf7f3;
    opacity: 0;
    overflow: hidden;
    transition: all 0.3s ease;
    pointer-events: none;

    ${({ $isOpen }) =>
      $isOpen &&
      css`
        pointer-events: all;
        opacity: 1;
        z-index: 100;
      `}
  }
`;

const NavListBox = styled.div`
  display: flex;
  justify-content: center;
  flex-direction: column;
  height: 100%;
  text-align: center;
`;

const NavList = styled.ul`
  position: relative;
  display: inline-flex;
  list-style: none;

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    .book-button {
      display: none;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    display: flex;
    flex-direction: column;
    margin: auto;
  }
`;

const NavItem = styled.li`
  color: ${(props) => props.theme.colors.brown};
  font-family: ${(props) => props.theme.fonts.grosa};
  font-size: 18px;
  letter-spacing: 0;

  a {
    display: inline-block;
    position: relative;
    cursor: pointer;

    &:before {
      position: absolute;
      content: '';
      left: 0;
      right: 0;
      bottom: 8px;
      height: 1px;
      background-color: ${(props) => props.theme.colors.brown};
      opacity: 0;
      transition: all 0.3s ease;
    }

    ${({ $active }) =>
      $active &&
      css`
        &:before {
          opacity: 1;
        }
      `}
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    margin-right: 40px;

    a {
      &:hover {
        &:before {
          opacity: 1;
        }
        opacity: 100%;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    font-size: 30px;
    line-height: 35px;
    margin-bottom: 40px;

    a {
      &:before {
        bottom: 1px;
      }
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_xs}px) {
    font-size: 20px;
    line-height: 35px;
    margin-bottom: 24px;
  }
`;

const MobileShape = styled.div`
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    position: absolute;
    content: '';
    left: -250px;
    right: -400px;
    top: -150px;
    bottom: -225px;
    background: url('/assets/icons/navigation/shapes_menu.svg') no-repeat center center;
    background-size: contain;
    z-index: -1;
  }
`;

const MenuList = () => {
  const { isOpen } = useContext(NavigationContext);

  return (
    <NavListWrapper $isOpen={isOpen}>
      <MobileShape />
      <NavListBox>
        <NavList>
          <ListItem url="/business">Business</ListItem>
          <ListItem url="/members">Members</ListItem>
          <ListItem extraClass="book-button" url="/eligibility">
            Enroll Now
          </ListItem>
          <ListItem extraClass="book-button">
            <SecondaryButton href="/request-demo">See Bloom</SecondaryButton>
          </ListItem>
        </NavList>
      </NavListBox>
    </NavListWrapper>
  );
};

export default MenuList;
