import { useEffect } from 'react';
import { DefaultSeo, SocialProfileJsonLd } from 'next-seo';
import { ThemeProvider } from 'styled-components';
import { UserAgentProvider } from '@quentin-sommer/react-useragent';
import Router from 'next/router';
import NProgress from 'nprogress';

import { CookieBanner } from '@/components/core/analytics';
import Layout from '@/components/core/layout';

import { DefaultSEO, SEOMetadata } from '@/config/seo-meta.js';
import { NavigationProvider } from '@/contexts/navigation-context';
import theme from '@/utils/styles-variables';
import GlobalStyle from '@/utils/global-styles';

import 'nprogress/nprogress.css';
import 'react-phone-number-input/style.css';
import 'slick-carousel/slick/slick.css';
import 'slick-carousel/slick/slick-theme.css';

import '../public/fonts.css';
import { renderSegmentScript, SegmentProvider } from '@/lib/segment';

function BloomApp({ Component, pageProps }) {
  useEffect(() => {
    NProgress.configure({ showSpinner: false });
    Router.events.on('routeChangeStart', () => NProgress.start());
    Router.events.on('routeChangeComplete', () => NProgress.done());
    Router.events.on('routeChangeError', () => NProgress.done());

    return () => {
      Router.events.off('routeChangeStart', () => null);
      Router.events.off('routeChangeComplete', () => null);
      Router.events.off('routeChangeError', () => null);
    };
  }, []);

  return (
    <UserAgentProvider ua="">
      <ThemeProvider theme={theme}>
        <SegmentProvider Component={Component}>
          <GlobalStyle />
          <NavigationProvider
            initialState={{ buttonLabel: pageProps?.navigation?.primaryCta || 'See Bloom' }}
          >
            <Layout
              withoutPageLayout={Component.withoutPageLayout}
              opaqueNavbar={Component.opaqueNavbar}
              primaryLogo={Component.primaryLogo}
              bannerInfo={pageProps?.bannerInfo}
            >
              <DefaultSeo
                {...DefaultSEO}
                additionalMetaTags={[
                  {
                    property: 'theme-color',
                    content: theme.colors.peach,
                  },
                ]}
                dangerouslySetAllPagesToNoIndex={process.env.NEXT_PUBLIC_ENV !== 'production'}
                dangerouslySetAllPagesToNoFollow={process.env.NEXT_PUBLIC_ENV !== 'production'}
              />
              <SocialProfileJsonLd
                type="Organization"
                name={DefaultSEO.openGraph.site_name}
                url={DefaultSEO.openGraph.url}
                sameAs={[SEOMetadata.linkedInProfile]}
              />

              <CookieBanner />

              {renderSegmentScript()}

              <Component {...pageProps} />
            </Layout>
          </NavigationProvider>
        </SegmentProvider>
      </ThemeProvider>
    </UserAgentProvider>
  );
}

export default BloomApp;
