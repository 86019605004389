const DefaultSEO = {
  defaultTitle: 'Bloom',
  titleTemplate: '%s | Bloom',
  openGraph: {
    type: 'website',
    locale: 'en_US',
    url: 'https://hibloom.com',
    site_name: 'Bloom',
  },
};

const SEOMetadata = {
  baseCanonical: DefaultSEO.openGraph.url,
  linkedInProfile: 'https://www.linkedin.com/company/bloom-care/',
};

export { DefaultSEO, SEOMetadata };
