import { useContext, useState, createContext, useMemo } from 'react';

const NavigationContext = createContext();

const defaultStateValues = {
  buttonLabel: null,
  isOpen: false,
  scrolled: false,
  transparent: false,
};

export const useNavigationContext = () => useContext(NavigationContext);

export const NavigationProvider = ({ children, initialState = {} }) => {
  const defaultState = useMemo(() => ({ ...defaultStateValues, ...initialState }), [initialState]);

  const [scrolled, setScrolled] = useState(defaultState.scrolled);
  const [isOpen, setIsOpen] = useState(defaultState.isOpen);
  const [transparent, setTransparent] = useState(defaultState.transparent);
  const [buttonLabel, setButtonLabel] = useState(defaultState.buttonLabel);

  return (
    <NavigationContext.Provider
      value={{
        scrolled,
        setScrolled,
        isOpen,
        setIsOpen,
        transparent,
        setTransparent,
        buttonLabel,
        setButtonLabel,
      }}
    >
      {children}
    </NavigationContext.Provider>
  );
};

export default NavigationContext;
