import { useEffect } from 'react';
import Router from 'next/router';
import Script from 'next/script';
import * as segmentSnippet from '@segment/snippet';

const IS_ENABLED =
  (process.env.NEXT_PUBLIC_ENV === 'production' || process.env.NEXT_PUBLIC_ENV === 'staging') &&
  !!process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY;

export function renderSegmentScript() {
  if (!IS_ENABLED) return null;

  const opts = {
    apiKey: process.env.NEXT_PUBLIC_SEGMENT_WRITE_KEY,
    // note: the page option only covers SSR tracking.
    // Page.js is used to track other events using `window.analytics.page()`
    page: true,
  };

  return (
    <Script id="segment-script" dangerouslySetInnerHTML={{ __html: segmentSnippet.min(opts) }} />
  );
}

export function SegmentProvider({ children }) {
  useEffect(() => {
    if (!IS_ENABLED) return;

    const handleRouteChange = (url) => {
      if (url) {
        window?.analytics?.page(url, {
          page: url,
        });
      }
    };

    Router.events.on('routeChangeComplete', handleRouteChange);

    return () => {
      Router.events.off('routeChangeComplete', handleRouteChange);
    };
  }, []);

  return <>{children}</>;
}

export function trackEvent(eventName, eventProps) {
  if (!IS_ENABLED) return null;

  window?.analytics?.track(eventName, eventProps);
}

export function identify(identifyProps) {
  if (!IS_ENABLED) return null;

  window?.analytics?.identify(identifyProps);
}
