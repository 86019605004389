const theme = {
  colors: {
    light: '#ECEAE6',
    light_peach: '#EED5C4',
    dark_peach: '#E08477',
    brown: '#30261D',
    purple: '#AE95DA',
    dark_purple: '#B9A1E2',
    peach: '#FFA489',
    white: '#FFFFFF',
    dark_brown: '#28231F',
    gradient: 'linear-gradient(127.16deg, #EED5C4 55.04%, #AE95DA 103.96%)',
    error: '#EB5454',
    light_bg: '#FCF7F3',
    light_card: '#F7EAE1',
  },

  fonts: {
    grosa: 'Grosa Trial, sans-serif',
    nantes_book: 'Nantes Trial Book, serif',
    nantes_light: 'Nantes Trial Light, serif',
  },

  breakpoints: {
    max_xs: 479,
    min_sm: 480,
    max_sm: 767,
    min_md: 768,
    max_md: 991,
    min_lg: 992,
    max_lg: 1199,
    min_xl: 1200,
    min_xxl: 1400,
  },
};

export default theme;
