import styled from 'styled-components';
import { useContext } from 'react';
import { useRouter } from 'next/router';

import NavigationContext from '@/contexts/navigation-context';

import DefaultLogo from '../../../public/assets/bloom_logo.svg';
import LogoBrown from '../../../public/assets/bloom_logo_brown.svg';

const Logo = styled.button`
  display: inline-flex;
  cursor: pointer;
  border: none;
  box-shadow: none;
  background-color: transparent;
  width: 100%;
  z-index: 1000;
  height: 22px;
  align-items: center;

  img,
  svg {
    height: 100%;
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    img,
    svg {
      width: 102px;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    text-align: center;
    height: 100%;
    display: flex;
    justify-content: center;
    flex-direction: column;
    img,
    svg {
      height: 28px;
      margin: auto;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    svg {
      width: 102px;
    }
  }
`;

const LogoWrapper = ({ className, primary, ...rest }) => {
  const { setIsOpen } = useContext(NavigationContext);
  const router = useRouter();

  return (
    <Logo
      className={className}
      onClick={() => {
        setIsOpen(false);
        router.push('/');
      }}
      {...rest}
    >
      {primary ? <DefaultLogo /> : <LogoBrown />}
    </Logo>
  );
};

export default LogoWrapper;
