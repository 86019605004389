import styled, { css } from 'styled-components';
import { useContext } from 'react';

import NavigationContext from '@/contexts/navigation-context';

const Hamburguer = styled.button`
  display: none;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 0 0 0 auto;
    padding: 0;
    border: none;
    border-top: 8px solid transparent;
    background: none;
    display: block;
    width: 24px;
    height: 22px;
    transform: rotate(0deg);
    transition: all 0.5s ease-in-out;
    cursor: pointer;
    position: relative;
    user-select: none;
    z-index: 100;

    span {
      display: block;
      position: absolute;
      height: 2px;
      width: 100%;
      background: ${(props) => props.theme.colors.brown};
      opacity: 1;
      transform: rotate(0deg);
      transition: all 0.3s ease-in-out;

      &:nth-child(1) {
        top: 0;
      }

      &:nth-child(2) {
        top: 7px;
        width: 75%;
        right: 0;
      }
    }

    ${(props) =>
      props.open &&
      css`
        span {
          &:nth-child(1) {
            transform: rotate(45deg);
            top: 0;
            width: 100%;
          }

          &:nth-child(2) {
            width: 100%;
            top: 0;
            transform: rotate(-45deg);
          }
        }
      `}
  }
`;

const NavMenuButton = () => {
  const { isOpen, setIsOpen } = useContext(NavigationContext);
  return (
    <Hamburguer open={isOpen} onClick={() => setIsOpen(!isOpen)} type="button">
      <span />
      <span />
    </Hamburguer>
  );
};

export default NavMenuButton;
