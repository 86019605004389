import Script from 'next/script';

const GTM_ID = process.env.NEXT_PUBLIC_GTM_ID;

export function pageview(url) {
  if (!GTM_ID) return null;

  window.dataLayer.push({
    event: 'pageview',
    page: url,
  });
}

export function trackEvent(event, props = {}) {
  if (!window.dataLayer) return null;

  window.dataLayer.push({
    event: event,
    ...props,
  });
}

export function insertGTMScript() {
  if (!GTM_ID) return null;

  return (
    // eslint-disable-next-line @next/next/inline-script-id
    <Script
      strategy="afterInteractive"
      dangerouslySetInnerHTML={{
        __html: `
            (function(w,d,s,l,i){w[l]=w[l]||[];w[l].push({'gtm.start':
            new Date().getTime(),event:'gtm.js'});var f=d.getElementsByTagName(s)[0],
            j=d.createElement(s),dl=l!='dataLayer'?'&l='+l:'';j.async=true;j.src=
            'https://www.googletagmanager.com/gtm.js?id='+i+dl;f.parentNode.insertBefore(j,f);
            })(window,document,'script','dataLayer', '${GTM_ID}');
          `,
      }}
    />
  );
}

export function insertGTMNoScript() {
  if (!GTM_ID) return null;

  return (
    <noscript>
      <iframe
        src={`https://www.googletagmanager.com/ns.html?id=${GTM_ID}`}
        height="0"
        width="0"
        style={{ display: 'none', visibility: 'hidden' }}
      />
    </noscript>
  );
}
