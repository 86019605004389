import { trackEvent } from '@/lib/segment';

export const ACTIONS = {
  CLICK_ENROLL: 'click_enroll',
  CLICK_CHECK_ELIGIBILITY: 'check_eligibility',
  DEMO_REQUEST: 'demo_request',
  NAVIGATION: 'navigation',
};

export const BUTTON_TYPES = {
  NAVIGATION: 'navigation',
  SUBMIT: 'submit',
};

const EVENT_NAMES = {
  BUTTON_CLICKED: 'Button Clicked',
  CLIENT_ELIGIBILITY_CHECKED: 'Client Eligibility Checked',
  DEMO_REQUESTED: 'Demo Requested',
  GATED_RESOURCES_REQUESTED: 'Gated Resources Requested',
  NEWSLETTER_SUBSCRIBED: 'Newsletter Subscribed',
};

export function trackButtonClicked({
  action,
  buttonText,
  buttonLocation = '',
  buttonType = '',
  destination = '',
  url,
  pageTitle = '',
}) {
  let title = pageTitle;

  if (url && !pageTitle) {
    const urlSplit = url.split('/');
    title = urlSplit[urlSplit.length - 1].replace('-', ' ');
  }

  trackEvent(EVENT_NAMES.BUTTON_CLICKED, {
    action,
    button_text: buttonText,
    button_type: buttonType,
    button_location: buttonLocation,
    page_url: url,
    destination,
    page_title: title,
  });
}

export function trackClientEligibilityChecked({
  clientName,
  clientId,
  clientCountry = undefined,
  isValid,
}) {
  trackEvent(EVENT_NAMES.CLIENT_ELIGIBILITY_CHECKED, {
    client_name: clientName,
    client_id: clientId,
    client_country: clientCountry,
    flg_client_exists: isValid,
  });
}

export function trackGatedResourcesRequested(properties) {
  trackEvent(EVENT_NAMES.GATED_RESOURCES_REQUESTED, properties);
}

export function trackDemoRequested(properties) {
  trackEvent(EVENT_NAMES.DEMO_REQUESTED, properties);
}

export function trackNewsletterSubscribed(properties) {
  trackEvent(EVENT_NAMES.DEMO_REQUESTED, properties);
}
