import PropTypes from 'prop-types';
import styled from 'styled-components';

import config from './config';

const Container = styled.div`
  margin-right: auto;
  margin-left: auto;
  width: 100%;
  max-width: ${(p) => `${config(p).containerWidth}px`};
  padding-right: ${(p) => `${config(p).outerMargin}px`};
  padding-left: ${(p) => `${config(p).outerMargin}px`};
`;

Container.displayName = 'Container';

Container.propTypes = {
  children: PropTypes.node,
};

export default Container;
