import React, { useContext } from 'react';
import Media from 'react-media';
import styled, { css } from 'styled-components';
import { Container } from '@/components/flexboxgrid';
import { RemoveScroll } from 'react-remove-scroll';
import { UAContext } from '@quentin-sommer/react-useragent';

import Hamburguer from './burguer';
import LogoButton from './logo';
import List from './list';

import Button, { SecondaryButton } from '@/components/core/button';

import NavigationContext from '@/contexts/navigation-context';

import theme from '@/utils/styles-variables';
import { ACTIONS, BUTTON_TYPES, trackButtonClicked } from '@/utils/track';

const MenuWrapper = styled.div`
  width: 100%;
  position: absolute;
  left: 0;
  right: 0;
  z-index: 1000;
  background-color: transparent;
  transition: all 0.3s ease;

  ${({ $scrolled, $opaque }) =>
    ($scrolled || $opaque) &&
    css`
      background-color: ${(props) => props.theme.colors.white};
      box-shadow: rgba(233, 220, 211, 0.4) 0 2px 10px 0;
    `}

  ${({ $fixed }) =>
    $fixed &&
    css`
      position: fixed;
      top: 0;
      min-height: 80px;
    `}
`;

const Menu = styled.div`
  display: flex;
  flex-direction: row;
`;

const MenuItem = styled.div`
  padding: 19px 0;

  &:first-child {
    order: 1;
    width: 40%;
  }

  &:nth-child(2) {
    order: 2;
    width: 20%;
  }

  &:nth-child(3) {
    order: 3;
    width: 40%;
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_lg}px) {
    line-height: 42px;
    &:nth-child(3) {
      text-align: right;
    }
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding: 25px 0;
    z-index: 10000;
    height: 72px;
    &:first-child {
      order: 2;
      width: 20%;
      height: 0;
    }

    &:nth-child(2) {
      order: 1;
      width: 40%;
    }

    &:nth-child(3) {
      order: 3;
      width: 40%;
    }
  }
`;

const HelperDiv = styled.div`
  height: 80px;
  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    height: 72px;
  }
`;

const TransparentButton = styled(Button)`
  font-size: 18px;
  font-weight: normal;
  background-color: transparent;
  color: ${(props) => props.theme.colors.dark_brown};
  border-color: transparent;
  position: relative;

  &:before {
    position: absolute;
    content: '';
    left: 23px;
    right: 23px;
    bottom: 8px;
    height: 1px;
    background-color: ${(props) => props.theme.colors.brown};
    opacity: 0;
    transition: all 0.3s ease;
  }

  &:hover {
    background-color: transparent;
    color: ${(props) => props.theme.colors.dark_brown};
    &:before {
      opacity: 1;
    }
  }

  @media (min-width: ${(props) => props.theme.breakpoints.min_sm}px) {
    padding-right: 20px;
    margin-right: 20px;
  }
`;

const Navigation = ({ opaque, fixed, primaryLogo }) => {
  const { uaResults } = useContext(UAContext);
  const { scrolled, isOpen, buttonLabel } = useContext(NavigationContext);

  return (
    <RemoveScroll enabled={isOpen}>
      <MenuWrapper $scrolled={scrolled} $opaque={opaque} $fixed={fixed}>
        <Container>
          <Menu>
            <MenuItem>
              <List />
            </MenuItem>
            <MenuItem>
              <LogoButton primary={scrolled || primaryLogo} />
            </MenuItem>
            <MenuItem>
              <Media
                query={{ maxWidth: theme.breakpoints.max_md }}
                defaultMatches={uaResults.mobile}
              >
                {(matches) =>
                  matches ? (
                    <Hamburguer />
                  ) : (
                    <>
                      <TransparentButton
                        small
                        href="/eligibility"
                        onClick={() =>
                          trackButtonClicked({
                            action: ACTIONS.CLICK_ENROLL,
                            buttonLocation: 'navbar',
                            buttonText: 'Enroll Now',
                            buttonType: BUTTON_TYPES.NAVIGATION,
                            destination: '/eligibility',
                          })
                        }
                      >
                        Enroll Now
                      </TransparentButton>
                      <SecondaryButton
                        small
                        href="/request-demo"
                        onClick={() =>
                          trackButtonClicked({
                            action: ACTIONS.DEMO_REQUEST,
                            buttonLocation: 'navbar',
                            buttonText: buttonLabel,
                            buttonType: BUTTON_TYPES.NAVIGATION,
                            destination: '/request-demo',
                          })
                        }
                      >
                        {buttonLabel}
                      </SecondaryButton>
                    </>
                  )
                }
              </Media>
            </MenuItem>
          </Menu>
        </Container>
      </MenuWrapper>
      {opaque && <HelperDiv />}
    </RemoveScroll>
  );
};

export default Navigation;
