import Link from 'next/link';
import { Col, Container, Row } from '@/components/flexboxgrid';
import styled, { css } from 'styled-components';

import Logo from '../../public/assets/bloom_logo_peach.svg';

export const GENERAL_EMAIL = 'hello@hibloom.com';
export const SUPPORT_EMAIL = 'help@hibloom.com';

function Footer() {
  return (
    <Wrapper>
      <Container>
        <StyledRow>
          <ExpandedCol>
            <Link href="/">
              <Logo data-testid="logo" />
            </Link>
            <AvailableLabel>
              {`While we use the word "women" in some of our communications, Bloom is designed for all
              individuals with vaginal anatomy regardless of gender identity.`}
            </AvailableLabel>
          </ExpandedCol>
          <LinksCol>
            <P>Bloom</P>
            <Link href="/business">
              <A>Business</A>
            </Link>
            <Link href="/members">
              <A>Members</A>
            </Link>
            <Link
              href="https://jobs.lever.co/swordhealth?department=BLOOM"
              target="_blank"
              rel="noopener noreferrer"
            >
              <A>Careers</A>
            </Link>
            <Link href="/faqs">
              <A>FAQs</A>
            </Link>
          </LinksCol>
          <LinksCol>
            <P>Contact Us</P>
            <Link href={`mailto:${GENERAL_EMAIL}`} target="_blank" rel="noopener noreferrer">
              <A>General inquiries: {GENERAL_EMAIL}</A>
            </Link>
            <Link href={`mailto:${SUPPORT_EMAIL}`} target="_blank" rel="noopener noreferrer">
              <A>Support: {SUPPORT_EMAIL}</A>
            </Link>
          </LinksCol>
        </StyledRow>
        <Separator />
        <StyledRow>
          <LinksCol>
            <Link href="/legal/privacy-statement">
              <Legal>Privacy Statement</Legal>
            </Link>
          </LinksCol>
          <LinksCol>
            <Link href="/legal/hipaa-notice-of-privacy-practices">
              <Legal>HIPAA Notice of Privacy Practices</Legal>
            </Link>
          </LinksCol>
          <LinksCol>
            <Link href="/legal/terms-and-conditions">
              <Legal>Terms & Conditions</Legal>
            </Link>
          </LinksCol>
          <LinksCol>
            <Link href="/references">
              <Legal>References</Legal>
            </Link>
          </LinksCol>
          <LastCol>
            <FadedP>Copyright {new Date().getFullYear()} All rights Reserved</FadedP>
          </LastCol>
        </StyledRow>
      </Container>
    </Wrapper>
  );
}

const Wrapper = styled.footer`
  background-color: ${({ theme }) => theme.colors.dark_brown};
  padding-top: 76px;
  padding-bottom: 30px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    padding-top: 60px;
    padding-bottom: 27px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    padding-top: 52px;
    padding-bottom: 29px;
  }
`;

const P = styled.p`
  font-size: 18px;
  line-height: 30px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
`;

const FadedText = css`
  font-size: 16px;
  line-height: 26px;
  font-style: normal;
  font-weight: normal;
  color: ${({ theme }) => theme.colors.white};
  opacity: 0.6;
`;

const A = styled.span`
  ${FadedText};

  transition: opacity 0.2s ease-in-out;
  &:hover {
    opacity: 1;
  }
`;

const Legal = styled(A)`
  font-size: 14px;
  line-height: 20px;
  flex-wrap: nowrap;
`;

const FadedP = styled.p`
  ${FadedText};
  font-size: 14px;
  line-height: 20px;
  text-align: end;
  white-space: nowrap;

  @media (max-width: 958px) {
    text-align: start;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    white-space: pre-wrap;
  }
`;

const LinksCol = styled(Col)`
  display: flex;
  flex-direction: column;
  min-width: 176px;

  ${P} {
    margin-bottom: 14px;
  }
  ${A} + ${A} {
    margin-top: 4px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    min-width: 163px;
  }

  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    ${P} {
      margin-bottom: 16px;
    }
  }
`;

const ExpandedCol = styled(Col)`
  flex: 1;
`;

const LastCol = styled(Col)`
  flex: 1;
`;

const StyledRow = styled(Row)`
  &:last-child {
    row-gap: 16px;
    ${LinksCol} {
      min-width: unset;
    }
  }
  @media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
    flex-direction: column;

    &:first-child {
      ${LinksCol}:nth-child(2) {
        margin-top: 57px;
      }
      ${LinksCol}:last-child {
        margin-top: 32px;
      }
    }
    &:last-child {
      ${ExpandedCol} {
        margin-top: 44px;
      }
    }
  }
`;

const Separator = styled.div`
  border-bottom: 1px solid #59514a;
  margin: 64px 0 32px;

  @media (max-width: ${(props) => props.theme.breakpoints.max_md}px) {
    margin: 64px 0 24px;
  }
`;

const AvailableLabel = styled.div`
  ${FadedText};
  margin-top: 20px;
  @media (min-width: ${(props) => props.theme.breakpoints.min_md}px) {
    max-width: 600px;
  }
`;

export default Footer;
