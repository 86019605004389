import styled, { css } from 'styled-components';
import Link from 'next/link';

import Loader from '../../public/assets/icons/loading-spinner.svg';

const ButtonStyle = styled.button`
  display: inline-flex;
  position: relative;
  border: 1px solid ${(props) => props.theme.colors.dark_brown};
  color: ${(props) => props.theme.colors.dark_brown};
  border-radius: 51px;
  font-size: 18px;
  justify-content: center;
  line-height: 18px;
  font-weight: 500;
  padding: 15px 28px;
  cursor: pointer;
  transition: all 0.3s ease;
  white-space: nowrap;
  max-width: 100%;
  overflow: hidden;
  text-overflow: ellipsis;
  text-align: center;

  ${(props) =>
    props.loading &&
    css`
      color: transparent !important;
      pointer-events: none;
      opacity: 0.2;
      cursor: pointer;
    `}

  ${(props) =>
    props.disabled &&
    css`
      pointer-events: none;
      opacity: 0.2;
      cursor: pointer;
    `}

  ${(props) =>
    props.small &&
    css`
      padding: 11px 23px;
      font-size: 16px;
    `}
  
  ${(props) =>
    props.fullwidth &&
    css`
      width: 100%;
      text-align: center;
    `}
  
    ${(props) =>
    props.wide &&
    css`
      padding: 16px 45px;
    `}
  
  &:hover {
    background-color: ${(props) => props.theme.colors.dark_brown};
    color: ${(props) => props.theme.colors.white};
  }
`;

const LoaderWrapper = styled.div`
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);

  @keyframes rotation {
    from {
      transform: rotate(0deg);
    }

    to {
      transform: rotate(359deg);
    }
  }

  .loading-spinner {
    animation: rotation 2.5s infinite linear;

    path {
      fill: ${(props) => props.theme.colors.white};
    }
  }
`;

const Button = ({ type, onClick, children, href, ...rest }) => {
  if (href) {
    return (
      <ButtonStyle {...rest} href={href} as={Link} onClick={onClick}>
        {children}
      </ButtonStyle>
    );
  }

  return (
    <ButtonStyle {...rest} type={type ?? 'button'} onClick={onClick}>
      {rest?.loading && (
        <LoaderWrapper>
          <Loader className="loading-spinner" />
        </LoaderWrapper>
      )}
      {children}
    </ButtonStyle>
  );
};

const PrimaryButton = styled(Button)`
  background-color: ${(props) => props.theme.colors.dark_brown};
  color: ${(props) => props.theme.colors.white};

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.dark_brown};
    }
  }

  @media (hover: hover) {
    &:hover {
      background-color: transparent;
      color: ${(props) => props.theme.colors.dark_brown};
    }
  }
`;

const SecondaryButton = styled(Button)`
  background-color: transparent;
  color: ${(props) => props.theme.colors.dark_brown};

  .loading-spinner {
    path {
      fill: ${(props) => props.theme.colors.dark_brown};
    }
  }

  &:hover {
    background-color: ${(props) => props.theme.colors.dark_brown};
    color: ${(props) => props.theme.colors.white};
  }
`;

export { PrimaryButton, SecondaryButton };

export default Button;
