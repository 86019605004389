import styled from 'styled-components';

import { Container } from '@/components/flexboxgrid';
import Link from 'next/link';
import ReactMarkdown from 'react-markdown';

const Wrapper = styled.div`
  display: flex;
  align-items: center;
  background-color: ${(props) => props.theme.colors.brown};
  min-height: 50px;
  padding: 12px 0;
`;

const Content = styled.div`
  color: white;
  font-style: normal;
  font-weight: 400;
  font-size: 15px;
  line-height: 26px;
  text-align: center;
  & p {
    display: inline;
  }
`;

const StyledLink = styled.a`
  display: inline;
  color: ${(props) => props.theme.colors.peach};
  text-decoration: underline;
  margin-left: 1ch;
`;
const Label = styled(ReactMarkdown)`
  display: inline-block;
`;
export default function Banner({ content }) {
  const { enabled, text, buttonLink, buttonLabel } = content;

  if (!enabled) {
    return null;
  }

  return (
    <Wrapper>
      <Container>
        <Content>
          <Label>{text}</Label>
          <StyledLink as={Link} href={buttonLink}>
            {buttonLabel}
          </StyledLink>
        </Content>
      </Container>
    </Wrapper>
  );
}
