import { Container } from '@/components/flexboxgrid';
import { createGlobalStyle } from 'styled-components';

import ResetCss from './reset-css';

const GlobalStyle = createGlobalStyle`
  ${ResetCss}
  body {
    margin: 0;
    font-family: ${(props) => props.theme.fonts.grosa};

		h1, h2, h3, h4 {
			font-family: ${(props) => props.theme.fonts.grosa};
			color: ${(props) => props.theme.colors.tangaroa};
		}

		h1 {
			font-size: 60px;
			font-weight: 900;
			line-height: 70px;
			@media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
				font-size: 40px;
				line-height: 50px;
			}
		}

		h2 {
			font-size: 50px;
			font-weight: 700;
			line-height: 60px;
			@media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
				font-size: 30px;
				line-height: 40px;
			}
		}

		h3 {
			font-size: 40px;
			font-weight: 700;
			line-height: 52px;
			@media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
				font-size: 25px;
				line-height: 35px;
				}
		}

		h4 {
			font-size: 30px;
			font-weight: 500;
			line-height: 40px;
			@media (max-width: ${(props) => props.theme.breakpoints.max_sm}px) {
				font-size: 20px;
				line-height: 25px;
			}
		}
	}

  html {
    box-sizing: border-box;
    overflow-x: hidden;
  }

  *,
  *:before,
  *:after {
    box-sizing: inherit;
  }

  ${Container} {
    max-width: 1306px;
    padding-left: 45px;
    padding-right: 45px;
    @media (max-width: ${(props) => props.theme.breakpoints.max_sm - 1}px) {
        padding-left: 20px;
        padding-right: 20px;
    }
  }

  #nprogress .bar{
    height: 4px;
    background: ${(props) => props.theme.colors.peach};

    .peg {
      box-shadow: ${(props) =>
        `0 0 10px ${props.theme.colors.peach}, 0 0 5px ${props.theme.colors.peach}`};
    }
  }

  .ReactModal__Overlay {
    opacity: 0;
    transition: opacity 0.2s ease-in-out;
  }

  .ReactModal__Overlay--after-open{
    opacity: 1;
  }

  .ReactModal__Overlay--before-close{
    opacity: 0;
  }
  
`;

export default GlobalStyle;
