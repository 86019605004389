import { useContext, useEffect } from 'react';
import Head from 'next/head';
import styled, { css } from 'styled-components';
import { useInView } from 'react-intersection-observer';

import Banner from '@/components/core/banner';
import Navigation from '@/components/core/navigation';
import Footer from '@/components/core/footer';

import NavigationContext from '@/contexts/navigation-context';

const ContentWrapper = styled.div`
  overflow: hidden;
`;

const Main = styled.main`
  min-height: calc(100vh - 309px);
  overflow: hidden;

  ${(props) =>
    props.withoutPageLayout &&
    css`
      min-height: 100vh;
    `}
`;

const StandardLayout = ({ withoutPageLayout, opaqueNavbar, primaryLogo, bannerInfo, children }) => {
  const [ref, inView] = useInView({
    threshold: 0,
    initialInView: true,
    rootMargin: opaqueNavbar ? '-80px' : '0px',
  });
  const { setScrolled } = useContext(NavigationContext);

  useEffect(() => {
    setScrolled(!inView);
  }, [inView]);

  return (
    <div>
      <Head>
        <link
          rel="preload"
          href="/fonts/GrosaTrial/GrosaTrial-Light.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/GrosaTrial/GrosaTrial-Regular.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/GrosaTrial/GrosaTrial-Medium.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/GrosaTrial/GrosaTrial-MediumItalic.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/NantesTrial/NantesTrial-BookItalic.otf"
          as="font"
          crossOrigin=""
        />
        <link
          rel="preload"
          href="/fonts/NantesTrial/NantesTrial-LightItalic.otf"
          as="font"
          crossOrigin=""
        />
      </Head>
      <ContentWrapper>
        {!withoutPageLayout && (
          <div className="navigation-observer" ref={ref}>
            {bannerInfo && <Banner content={bannerInfo} />}
            <Navigation primaryLogo={primaryLogo} opaque={opaqueNavbar} fixed={!inView} />
          </div>
        )}
        <Main withoutPageLayout={withoutPageLayout}>{children}</Main>
        {!withoutPageLayout && <Footer />}
      </ContentWrapper>
    </div>
  );
};

export default StandardLayout;
